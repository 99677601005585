import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Cupones from './components/Cupones';
import AdminCupones from './components/AdminCupones'; // Importa el nuevo componente

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/cupones" element={<Cupones />} />
        <Route path="/admin" element={<AdminCupones />} /> {/* Nueva ruta */}
      </Routes>
    </Router>
  );
}

export default App;

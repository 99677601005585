import React, { useEffect, useState } from 'react';
import { db, auth, collection, doc, getDocs, updateDoc, increment, arrayUnion, onAuthStateChanged } from '../firebase';
import ReactGA from 'react-ga';
import { FaWhatsapp, FaTags, FaGift, FaBullhorn } from 'react-icons/fa'; // Importa los iconos adicionales
import './styles.css';

const Cupones = () => {
  const [cupones, setCupones] = useState([]);
  const [user, setUser] = useState(null);
  const [activeTab, setActiveTab] = useState('consumidor');

  useEffect(() => {
    const fetchCupones = async () => {
      const cuponesCollection = collection(db, 'cupones');
      const cuponesSnapshot = await getDocs(cuponesCollection);
      setCupones(cuponesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(user);

      if (user) {
        const userDoc = doc(db, 'users', user.uid);
        const userSnap = await getDocs(userDoc);
        if (userSnap.exists()) {
          const userData = userSnap.data();
          if (userData.tipoUsuario === 'prestador') {
            const categoriaDoc = doc(db, 'categorias', user.uid);
            const categoriaSnap = await getDocs(categoriaDoc);
            if (categoriaSnap.exists() && categoriaSnap.data().enable) {
              setActiveTab('prestador');
            } else {
              setActiveTab('consumidor');
            }
          } else {
            setActiveTab('consumidor');
          }
        }
      }

      fetchCupones();
    });

    return () => unsubscribe();
  }, []);

  const handleClick = async (id) => {
    ReactGA.event({
      category: 'Cupon',
      action: 'Click',
      label: id
    });

    const cuponDoc = doc(db, 'cupones', id);
    await updateDoc(cuponDoc, {
      clicks: increment(1),
      interesados: arrayUnion(user.uid)
    });
  };

  const filteredCupones = cupones.filter(cupon => {
    if (activeTab === 'prestador') {
      return cupon.cuponParaPrestador;
    }
    return cupon.cuponParaConsumidorFinal;
  });

  return (
    <div className="container">
      <h1>Cupones</h1>
      <div className="tab-buttons">
        <button
          onClick={() => setActiveTab('consumidor')}
          className={activeTab === 'consumidor' ? 'active' : ''}
        >
          Consumidor Final
        </button>
        <button
          onClick={() => setActiveTab('prestador')}
          className={activeTab === 'prestador' ? 'active' : ''}
        >
          Prestadores de Servicio
        </button>
      </div>
      <div className="cupones">
        {filteredCupones.map(cupon => (
          <div className="cupon" key={cupon.id}>
            <div className="cupon-image-wrapper">
              <a href={cupon.linkImagen} target="_blank" rel="noopener noreferrer">
                <img src={cupon.imagen} alt="Imagen del Cupón" onClick={() => handleClick(cupon.id)} />
                {cupon.tipoCupon === 'Descuento' && (
                  <FaTags className="cupon-icon" />
                )}
                {cupon.tipoCupon === 'Oferta' && (
                  <FaGift className="cupon-icon" />
                )}
                {cupon.tipoCupon === 'Promocion' && (
                  <FaBullhorn className="cupon-icon" />
                )}
              </a>
            </div>
            <a href={cupon.linkWhatsapp} target="_blank" onClick={() => handleClick(cupon.id)} rel="noopener noreferrer">
              <FaWhatsapp size={20} /> WhatsApp
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Cupones;

import { initializeApp } from 'firebase/app';
import { getFirestore, collection, doc, getDocs, updateDoc, deleteDoc, addDoc, increment, arrayUnion } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';


const firebaseConfig = {
    apiKey: "AIzaSyBJi-AFRiJf0FeFhhsNF6csVWtRgU9wLAk",
    authDomain: "mascotamasapp-staging.firebaseapp.com",
    projectId: "mascotamasapp-staging",
    storageBucket: "mascotamasapp-staging.appspot.com",
    messagingSenderId: "865737329208",
    appId: "1:865737329208:web:17cac6eb29850495c2b4cd"
  };
  const app = initializeApp(firebaseConfig);

  const db = getFirestore(app);
  const auth = getAuth(app);
  
  export { db, auth, collection, doc, getDocs, updateDoc, deleteDoc, addDoc, increment, arrayUnion, onAuthStateChanged };
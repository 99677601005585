import React, { useEffect, useState } from 'react';
import { db, collection, doc, getDocs, addDoc, updateDoc, deleteDoc } from '../firebase';
import './AdminCupones.css';

const AdminCupones = () => {
  const [cupones, setCupones] = useState([]);
  const [currentCupon, setCurrentCupon] = useState(null);
  const [form, setForm] = useState({
    tipoCupon: '',
    imagen: '',
    linkImagen: '',
    linkWhatsapp: '',
    cuponParaPetshops: false,
    cuponParaConsumidorFinal: false,
    cuponParaVeterinarias: false,
    cuponParaPeluquerias: false,
    cuponParaPrestador: false,
    pais: '',
    empresa: '',
    emailEmpresa: '',
    telefonoEmpresa: '',
    activo: false,
  });

  useEffect(() => {
    fetchCupones();
  }, []);

  const fetchCupones = async () => {
    const cuponesCollection = collection(db, 'cupones');
    const cuponesSnapshot = await getDocs(cuponesCollection);
    setCupones(cuponesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setForm({
      ...form,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (currentCupon) {
      // Editar cupón existente
      const cuponDoc = doc(db, 'cupones', currentCupon.id);
      await updateDoc(cuponDoc, form);
    } else {
      // Crear nuevo cupón
      await addDoc(collection(db, 'cupones'), form);
    }
    resetForm();
    fetchCupones();
  };

  const resetForm = () => {
    setForm({
      tipoCupon: '',
      imagen: '',
      linkImagen: '',
      linkWhatsapp: '',
      cuponParaPetshops: false,
      cuponParaConsumidorFinal: false,
      cuponParaVeterinarias: false,
      cuponParaPeluquerias: false,
      cuponParaPrestador: false,
      pais: '',
      empresa: '',
      emailEmpresa: '',
      telefonoEmpresa: '',
      activo: false,
    });
    setCurrentCupon(null);
  };

  const handleEdit = (cupon) => {
    setCurrentCupon(cupon);
    setForm(cupon);
  };

  const handleDelete = async (id) => {
    const confirmation = window.confirm("¿Está seguro de que desea eliminar este cupón?");
    if (confirmation) {
      await deleteDoc(doc(db, 'cupones', id));
      fetchCupones();
    }
  };

  const handleCancel = () => {
    resetForm();
  };

  return (
    <div className="admin-container">
      <h1>Administrar Cupones</h1>
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <select name="tipoCupon" value={form.tipoCupon} onChange={handleInputChange} required>
            <option value="">Seleccione Tipo de Cupón</option>
            <option value="Oferta">Oferta</option>
            <option value="Promocion">Promoción</option>
            <option value="Lanzamiento">Lanzamiento</option>
            <option value="Descuento">Descuento</option>
          </select>
          <input type="text" name="imagen" value={form.imagen} onChange={handleInputChange} placeholder="URL de la Imagen" required />
          <input type="text" name="linkImagen" value={form.linkImagen} onChange={handleInputChange} placeholder="Link de la Imagen" required />
          <input type="text" name="linkWhatsapp" value={form.linkWhatsapp} onChange={handleInputChange} placeholder="Link de WhatsApp" required />
          <div className="checkbox-group">
            <label>
              <input type="checkbox" name="cuponParaConsumidorFinal" checked={form.cuponParaConsumidorFinal} onChange={handleInputChange} />
              Cupón para Consumidores Finales
            </label>
            <label>
              <input type="checkbox" name="cuponParaPrestador" checked={form.cuponParaPrestador} onChange={handleInputChange} />
              Cupón para Prestador
            </label>
          </div>
          <div className="checkbox-group">
            <label>
              <input type="checkbox" name="cuponParaPetshops" checked={form.cuponParaPetshops} onChange={handleInputChange} />
              Para Petshops
            </label>
            <label>
              <input type="checkbox" name="cuponParaVeterinarias" checked={form.cuponParaVeterinarias} onChange={handleInputChange} />
              Para Veterinarias
            </label>
            <label>
              <input type="checkbox" name="cuponParaPeluquerias" checked={form.cuponParaPeluquerias} onChange={handleInputChange} />
              Para Peluquerías
            </label>
          </div>
          <input type="text" name="pais" value={form.pais} onChange={handleInputChange} placeholder="País" required />
          <input type="text" name="empresa" value={form.empresa} onChange={handleInputChange} placeholder="Empresa" required />
          <input type="email" name="emailEmpresa" value={form.emailEmpresa} onChange={handleInputChange} placeholder="Email de la Empresa" required />
          <input type="text" name="telefonoEmpresa" value={form.telefonoEmpresa} onChange={handleInputChange} placeholder="Teléfono de la Empresa" required />
          <label>
            <input type="checkbox" name="activo" checked={form.activo} onChange={handleInputChange} />
            Activo
          </label>
          <div className="form-buttons">
            <button type="submit">{currentCupon ? 'Actualizar' : 'Crear'} Cupón</button>
            {currentCupon && <button type="button" onClick={handleCancel}>Cancelar</button>}
          </div>
        </form>
        {currentCupon && (
          <div className="cupon-preview">
            <h2>Previsualización del Cupón</h2>
            <img src={form.imagen} alt="Previsualización del cupón" />
            <p>Tipo: {form.tipoCupon}</p>
            <p>Empresa: {form.empresa}</p>
            <p>Activo: {form.activo ? 'Sí' : 'No'}</p>
          </div>
        )}
      </div>
      <div className="cupones-list">
        {cupones.map(cupon => (
          <div key={cupon.id} className="cupon-item">
            <img src={cupon.imagen} alt="Imagen del Cupón" className="cupon-thumbnail" />
            <div className="cupon-details">
              <p>Tipo: {cupon.tipoCupon}</p>
              <p>Empresa: {cupon.empresa}</p>
              <p>Activo: {cupon.activo ? 'Sí' : 'No'}</p>
              <button onClick={() => handleEdit(cupon)}>Editar</button>
              <button onClick={() => handleDelete(cupon.id)}>Eliminar</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminCupones;
